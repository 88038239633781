import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import SupportEmail from '../../components/supportEmail/SupportEmail';
import useIsMobile from '../../hooks/useIsMobile';

function TermsAndConditionsPage() {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={`px-4 md:px-24 mx-auto py-10 leading-6 ${
        isMobile ? 'px-5' : ''
      }`}
    >
      {/* {isMobile && (
        <button
          className="mt-2 mb-6 block"
          onClick={() => window.history.back()}
        >
          <FaArrowLeft />
        </button>
      )} */}
      {/* First Section */}
      <section>
        <Toaster />
        {!isMobile && (
          <h1 className='text-[1.25rem] leading-[2rem] md:text-[40px] text-[#2D2D2D] md:leading-[40px] mb-8 font-bold'>
            Terms and Conditions for using Savers App Online Services
          </h1>
        )}
        <p className='mb-6'>Effective Date: 1st August, 2024.</p>
        <div className='space-y-4'>
          <p>
            IMPORTANT: PLEASE READ THESE TERMS AND CONDITIONS OF USE (“TERMS”)
            CAREFULLY BEFORE USING THE PROGRAM, AS THEY AFFECT YOUR LEGAL RIGHTS
            AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED TO, WAIVERS OF RIGHTS,
            LIMITATION OF LIABILITY, ARBITRATION OF DISPUTES AND YOUR INDEMNITY
            TO US. BY ACCESSING OR USING THIS PROGRAM, YOU AGREE TO THESE TERMS.
            IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THIS PROGRAM.
          </p>
          <p>
            The Savers App Program (the <strong>“Program”</strong>) is offered
            to our valued customers (<strong>“you”</strong> or{' '}
            <strong>“your”</strong> as the context dictates) by Savers App Inc.(
            <strong>“Savers App”, “we”, “us”</strong> or <strong>“our”</strong>{' '}
            as the context dictates) under the terms and conditions (the
            “Program Terms”) stated below.
          </p>
          <p>
            Program Terms govern your use of the program and all related
            services and features such as widgets, mobile applications, browser
            extensions, content, and downloads available through this APP or
            that post these Terms (the “APP” or “Site”) regardless of how you
            access or use it. Savers App Inc., 1111B S Governors Ave, Unit
            21598, Dover, DE 19904, T: +1 (385) 518-0190 or its subsidiaries
            and/or affiliates (collectively, “Savers App”, “us” or “we”) operate
            this Program. The Program may be located at ‘saversapp.com’ or may
            be hosted under any other domain Savers App Inc. or its partner(s)
            elect to operate the Program at.
          </p>
          <p>
            We use third-party technology and services provided by our
            technology vendor (<strong>“Program Vendor”</strong>) to provide you
            with the functionality of the Program. These Program Terms
            incorporate the terms and conditions the Program Vendor, including
            certain privacy policies, that such third parties require us and any
            end-users to abide by. You agree to abide by the Program Terms and
            understand that such providers are third-party beneficiaries under
            the Program Terms and will have the ability to claim damages against
            you if you breach the Program Terms.
          </p>
          <p>
            In addition, please review our Privacy Policy, which explains how we
            collect, use, and share your personal information. Program Terms
            along with our{' '}
            <Link
              to={`${
                isMobile ? '/privacy-policy?ismobile=true' : '/privacy-policy'
              }`}
              className='font-semibold text-blue-700 hover:underline whitespace-nowrap'
              onClick={() => window.scrollTo(0, 0)}
            >
              Privacy Policy
            </Link>{' '}
            as they are legally binding and have sections that contain important
            information about your rights and obligations in respect of the
            Program (including use, legal rights and liability, and your
            obligations and matters related to disputes, including the exclusive
            use of arbitration to resolve disputes). Each time you access and/or
            use the Program (other than simply read these Terms), you agree to
            be bound by and comply with all of these Terms. You also acknowledge
            and consent to our privacy practices as outlined in our Privacy
            Policy and agree that we may change, alter, or modify the settings
            or configurations on your device or computer used to access the
            Program to allow for or optimize your use/experience of using the
            Program.
          </p>
          <p>
            You are obligated to gain a complete understanding of the Terms and
            the functions and operation of the Program. In the event any FAQ
            comments, Program content, or any statements by our Customer Service
            Team conflict with these Terms, these Terms will govern.
          </p>
          <p>
            All capitalized terms, unless otherwise defined in the body of the
            Program Terms, are defined at the end of the Program Terms. By
            enrolling in the Programs, you agree to be bound by the Program
            Terms and our privacy policy. If you do not agree with the Program
            Terms please do not enrol in the Program.
          </p>
        </div>
      </section>

      {/* List Section */}
      <section className='px-2 md:px-0'>
        <ol className='list-decimal mt-8 bold-marker ml-[1.2rem]'>
          <li className='mb-5'>
            <h3 className='font-bold'>Definitions.</h3>
            <div className='mt-2 space-y-3'>
              <p>
                <strong>“Card”</strong> means an Eligible Card and an{' '}
                <strong>“Enrolled Card”</strong> is such an Eligible Card that
                has been registered for a Program by you under a Program
                Account.{' '}
              </p>
              <p>
                <strong>“Payout Card”</strong> means an Eligible Enrolled Card
                that has been designated by you under a Program Account, to
                receive the confirmed cashback rewards when available.
              </p>
              <p>
                <strong>“Card-linked Offer”</strong> or <strong>“Offer”</strong>{' '}
                means an offer to you from participating Merchants that is
                available via the Program and is redeemed in association with a
                Merchant by using your Enrolled Card.{' '}
              </p>
              <p>
                <strong>“Card-Use Data”</strong> means the transaction data
                provided by the corresponding Payment Network for an Enrolled
                Card and any data calculated by the Program related to that
                transaction data that is required to provide the benefits of the
                Program.{' '}
              </p>
              <p>
                <strong>“Cashback Reward”</strong> the amount paid out to you
                when you complete a Qualifying Transaction for a Card-linked
                Offer.
              </p>
              <p>
                <strong>“Eligible Card”</strong> means any Visa card, except
                without limitation, Visa Buxx, Flexible Spending Account (FSA)
                Visa cards, EBT Visa cards, and other Visa-branded cards whose
                transactions are not processed through the Visa U.S.A. payment
                system, and any Mastercard card processed through the Mastercard
                payment system.
              </p>
              <p>
                <strong>“Merchant”</strong> means a third-party business.{' '}
              </p>
              <p>
                <strong>“Click Offer”</strong> means an offer to you from a
                participating Merchants that is made available via the Program
                and is redeemed using a coupon, voucher, or redemption code
                provided to you through the Program.
              </p>
              <p>
                “Offer” means a Cashback Reward, Click Reward, or Matching made
                available to you in the Program by a Merchant.{' '}
              </p>
              <p>
                <strong>“Party”</strong> means either you or Savers App Inc, and{' '}
                <strong>“Parties”</strong> means both you and Savers App Inc..{' '}
              </p>
              <p>
                <strong>“Payment Network”</strong> or{' '}
                <strong>“Payment Network Provider”</strong> means Visa,
                Mastercard, and other payment network companies that participate
                in the Program.{' '}
              </p>
              <p>
                <strong>“Program Account”</strong> means the account that you
                set up to be entitled to receive card-linked Rounding and/or
                Cashback Rewards.{' '}
              </p>
              <p>
                <strong>“Qualifying Transactions”</strong> means a purchase in
                connection with an Offer and made with an Enrolled Card that, in
                our sole discretion, satisfies all the requirements of the
                Offer. Qualifying Transactions made with an Enrolled Card while
                the card is not in Good Standing will not accrue any rewards. An
                Enrolled Card will be in <strong>“Good Standing”</strong> if it
                (1) is in an active status and (2) is not lost or stolen. If you
                make a Qualifying Transaction in part with your Enrolled Card
                and in part with another form of payment, you must meet the
                terms of the Offer in the part of the purchase made with your
                Enrolled Card. You will only earn rewards on the part of the
                purchase made with your Enrolled Card.
              </p>
              <p>
                <strong>“Website/APP”</strong> means a website, any subdomains
                or an app that we utilize to offer you the Program.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Understanding our Role.</h3>
            <div className='flex mt-2'>
              <strong>a. </strong>
              <p className='ml-2'>
                a. By participating in the Program you are choosing to use the
                Program to receive and accept certain Offers from Merchants that
                reward you for making certain transactions. Various parties’
                technology and services collectively allow us to provide you
                with these benefits. Nothing under the Program Terms in any way
                reduces your obligations and rights under separate terms and
                conditions for your credit card or other services that you may
                be subscribed to with us or with third parties. We and our
                licensors do not make any particular suggestion or
                recommendation to you in respect of how you avail yourself of
                the Program. See the <strong>“Additional Disclaimer”</strong>{' '}
                section below for further limitations.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Right to Use & Grants.</h3>
            <div className='flex mt-2'>
              <strong>a. </strong>
              <p className='ml-2'>
                Subject to your compliance with the Program Terms, we grant you
                a non-sublicensable, non-transferable, non-exclusive, limited,
                and revocable right to access the Program.
              </p>
            </div>
            <div className='flex mt-2'>
              <strong>b. </strong>
              <p className='ml-2'>
                You grant us and our third-party licensors and service providers
                the right to access and utilize your Card transaction and
                Card-Use data to provide you with the agreed-upon benefits under
                the Program, including receipt of targeted, complementary and/or
                location-based offers presented via e-mail, push notifications,
                or through advertisements on other sites and mobile applications
                by us or the third-parties involved in the Program including
                Merchants, in compliance with our privacy policy.
              </p>
            </div>
            <div className='flex mt-2'>
              <strong>c. </strong>
              <p className='ml-2'>
                You grant us and our licensors the right to use Card
                transactions and Card-Use data for the purposes of improving
                services and the program. We do not sell your information,
                including your card transaction and Card-Use Data.
              </p>
            </div>
            <div className='flex mt-2'>
              <strong>d. </strong>
              <p className='ml-2'>
                Savers App Inc. and its licensors reserve all intellectual
                property rights in all elements (including but not limited to
                design, all text, graphics, content, video, logos, audio and the
                selection and arrangement of the foregoing) of the websites
                associated with the Program. You agree not to reproduce,
                duplicate, copy, sell, resell or exploit any content or feature
                on the Website.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3>
              <strong>Program Participation Conditions.</strong> The following
              conditions apply to you during your participation in the Program
            </h3>
            <div className='flex mt-2 ml-4'>
              <ol className='list-lower-alpha'>
                <li>
                  you must register for a Program Account on our APP and inform
                  us promptly of any changes to the information provided in the
                  creation of your Program Account; A general membership is
                  free. You are only permitted to register once and must provide
                  accurate name, phone and other mandatorily requested
                  information, which may change from time to time. All submitted
                  information must be true
                </li>
                <li>
                  you must manage your Program Account credentials and keep them
                  confidential and inform us if the security of your credentials
                  has been compromised. We may require you to reset your
                  password or take other steps to protect your Program Account.
                  We retain the right to take other actions as needed in respect
                  of your Program Account; Savers App Inc. will neither request
                  your passwords by email nor by telephone. You are liable for
                  any third-party use of your user account and your password.
                </li>
                <li>
                  if any communication you receive looks suspicious or has
                  hyperlinks that you do not expect, do not open the
                  communication or click the hyperlink. Instead, email us at{' '}
                  <SupportEmail />
                </li>
                <li>
                  only the Program Account holder can enroll their Card in the
                  Program;
                </li>
                <li>
                  if your Enrolled Card is cancelled or expires, you must update
                  the Program Account with current information in order to be
                  eligible to receive benefits under the Program; and
                </li>
                <li>
                  you may cease participation in the Program at any time by
                  deactivating your Program Account which will release you from
                  our Program Terms. You may deactivate your Program Account by
                  deactivating your Enrolled Cards in the Program settings. As
                  part of the process of deactivating your Program Account, you
                  must ensure that all Enrolled Cards have been unenrolled.
                </li>
                <li>
                  To be eligible to use the Program, you must be of the age of
                  majority (i.e., old enough to enter a binding contract) in the
                  jurisdiction in which they are located, and in no event
                  younger than 18 years of age and legal entities duly
                  incorporated and in good standing in the jurisdiction in which
                  they are located.
                </li>
              </ol>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>
              Savers App One-Time Password (OTP) Terms of Service
            </h3>
            <div className='flex mt-2 ml-4'>
              <ol className='list-lower-alpha'>
                <li>
                  Savers App is a mobile-first Card-Linked-Offers loyalty
                  program that is owned and operated by Savers App Inc.Members
                  of the program enjoy card-linked and online offers across
                  20,000+ merchants, receive tailored regional recommendations,
                  and gain access to popular online and off-line retailers
                  across top shopping categories in the US. Members earn cash
                  back rewards with participating merchants when using Visa or
                  Mastercard credit cards that they have enrolled with the
                  program through the Savers App mobile application.
                  Verification messages are always sent by SMS to the member’s
                  phone number. Additional SMS messages would be sent only if
                  you (the member) opt in for such communication from the
                  Notifications section in the My Account page in the app.
                </li>
                <li>
                  If you have opted in for other types of SMS communication, you
                  can opt out of such SMS service at any time by modifying your
                  Notification preferences in the app.
                </li>
                <li>
                  If you are experiencing issues with the messaging program you
                  can reply to the SMS message with the keyword HELP for more
                  assistance, or you can get help directly at
                  support@saversapp.com.
                </li>
                <li>
                  Carriers are not liable for delayed or undelivered messages.
                </li>
                <li>
                  As always, message and data rates may apply for any messages
                  sent to you from us and to us from you. You will receive
                  One-Time Password messages when you log in for the first time
                  and/or when you log in after being logged out of the app
                  voluntarily, after switching to another device, or due to
                  inactivity. Other types of messages will be sent based on the
                  preferences you select in the Notifications section of the “My
                  Account” page in the app. If you have any questions about your
                  text plan or data plan, it is best to contact your wireless
                  provider.
                </li>
                <li>
                  If you have any questions regarding privacy, please read our
                  privacy policy at{' '}
                  <Link
                    to={`${
                      isMobile
                        ? '/privacy-policy?ismobile=true'
                        : '/privacy-policy'
                    }`}
                    className='font-semibold text-blue-700 hover:underline whitespace-nowrap'
                  >
                    https://b2c.saversapp.com/privacy-policy
                  </Link>
                  .
                </li>
              </ol>
            </div>
          </li>

          <li className='mb-5'>
            <h3 className='font-bold'>
              Expulsion of Users, Inactivity & Change of Offers
            </h3>
            <div className='flex mt-2 ml-4'>
              <ol className='list-lower-alpha space-y-2'>
                <li>
                  Savers App Inc. has the discretion to immediately expel a
                  user, suspend or terminate a user’s account or licenses in its
                  business judgment, including, without limitation, if the user
                  has violated legal regulations, third-party rights, these
                  Terms; or has violated, endangered, or infringed upon a
                  legitimate interest of Savers App Inc., particularly in case
                  of outstanding payments or other matters for the protection of
                  other users.
                </li>
                <li>
                  Savers App Inc. will unenroll and void all previous Cashback
                  Rewards, in the event of inactivity, ie no Qualified
                  Transactions occurred for 90 days. Cards need to be
                  re-enrolled by the user and a qualified transaction must occur
                  within 15 days to avoid removal of the card from your profile.
                </li>
                <li>
                  Savers App Inc. is entitled to change (in particular to reduce
                  or suspend) its services or offerings, including, but not
                  limited to, changing its fee schedule, products & service
                  pricing and Cashback percentages, Points or Rewards offering
                  in its sole discretion at any time and without prior notice.
                  Savers App Inc. may also at its discretion discontinue the
                  Site or any part of it at any time.
                </li>
                <li>
                  You may terminate and delete your free membership/account at
                  any time. You can do so in your account settings.
                </li>
              </ol>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Program Mechanics</h3>
            <p className='ml-4'>
              Program participation automatically entitles each transaction
              completed with the Enrolled Card to be considered for eligibility
              for all Offers available under the Program. The Offers made
              available within the Program have the following specifications:{' '}
            </p>
            <div className='flex mt-2 ml-8'>
              <ol className='list-lower-alpha'>
                <li>
                  <h3 className='font-bold'>Cashback Rewards</h3>
                  <div>
                    <ol className='list-lower-roman ml-4'>
                      <li>
                        When you use your Enrolled Card in a transaction that
                        qualifies for an Offer (as determined by the Payment
                        Network), the Cashback Rewards you earn will be credited
                        to your Program Account by the Program. Only Eligible
                        Cards (e.g. Visa, Mastercard, and other eligible credit
                        cards, and certain Visa and Mastercard debit cards that
                        do not use pin pad for the transaction) are eligible to
                        be enrolled in and benefit from the program. The list of
                        eligible cards may change from time to time without any
                        liability to you.
                      </li>
                      <li>
                        Cashback Rewards credited to your Program Account will
                        be deposited back into your selected Payout card on a
                        monthly basis if and when confirmed cashback is
                        available and meets the minimum deposit value set by the
                        program. Cashback Rewards will be credited to your
                        Program Account within 120 days of the Qualifying
                        Transaction. However, we will not be liable for any
                        indirect, incidental, consequential, exemplary,
                        punitive, or special damages resulting from any failure
                        to post Rewards to your account in a timely manner. You
                        acknowledge and accept that you are solely responsible
                        for checking your Program Account regularly to verify
                        that Cashback Rewards have been properly posted.
                      </li>
                      <li>
                        Unless otherwise specified in the Program Terms or in an
                        Offer, the amount of Cashback Rewards will be calculated
                        based on the entire amount spent on an Enrolled Card in
                        a transaction. The Merchant may stipulate a maximum
                        eligible Cashback Reward amount or include or eliminate
                        gratuity amounts from the total amount spent on a given
                        transaction by an Enrolled Card for the purposes of
                        calculating Cashback Rewards. Please read the details of
                        any Offer that you wish to avail yourself of in respect
                        of your Enrolled Card transaction to ensure you fully
                        understand the Offer.{' '}
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  <h3 className='font-bold mt-2'>Click Offers</h3>
                  <div>
                    <ol className='list-lower-roman ml-4'>
                      <li>
                        Click Offers are digital vouchers, coupons, or codes
                        provided for use at the point of sale. Terms of Click
                        Offers are indicated in the text of the Offer and are
                        subject to Merchant discretion. Click Offers have no
                        monetary value. Cashback earned from click offers is
                        governed by the click offer terms and conditions.
                      </li>
                    </ol>
                  </div>
                </li>
              </ol>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>User Generated Content</h3>
            <div className='flex mt-2 ml-4'>
              <ol className='list-lower-alpha'>
                <li>
                  Where applicable, we allow users to post comments, reviews or
                  other content in certain areas of the program as well as on
                  our social media sites. Any content posted on our program or
                  on our social media sites (i.e. Facebook), including any files
                  attached to posts, expresses only the views of the author of
                  the message and does not necessarily reflect the views of
                  Savers App Inc. or any person or entity associated with it.
                  You agree that you will not use our program to post any
                  material, links to any material or files that are or contain
                  material that is knowingly false and/or defamatory,
                  inaccurate, abusive, vulgar, hateful, harassing, obscene,
                  profane, sexually oriented, threatening, invasive of a
                  person's privacy or otherwise violate any applicable law,
                  rules, regulations or Savers App Inc. policies. Savers App
                  Inc., nor any person or entity associated with it, will assume
                  any responsibility for the contents, accuracy, completeness or
                  validity of any information posted on or about our program
                </li>
                <li>
                  You further agree that: (a) you will not post or otherwise
                  transmit any material that is copyrighted or subject to a
                  third party's intellectual property rights without the express
                  permission of the holder of such rights, unless such rights
                  are owned by you; (b) you will not collect or store personal
                  data about other users; (c) you will not use the program for
                  any commercial purpose not expressly approved by the Savers
                  App Inc. in writing; and (d) you will not upload, post, email,
                  or otherwise transmit any advertising or promotional materials
                  or any other form of solicitation or unauthorised
                  communication.
                </li>
                <li>
                  You agree to indemnify and hold Savers App Inc., its parents,
                  subsidiaries, officers, employees, and website/APP contractors
                  and each of their officers, employees and agents harmless from
                  any claims, damages and expenses, including reasonable
                  attorneys' fees and costs, related to your violation of this
                  section 7, or any violations thereof by your dependents or
                  which arises from the use of content you submitted, posted, or
                  otherwise provided to Savers App Inc. or this Site. Savers App
                  Inc. reserves the right in its absolute discretion to
                  terminate or suspend the accounts of users who infringe this
                  section 7
                </li>
                <li>
                  You hereby grant Savers App Inc. a perpetual, world-wide,
                  royalty-free licence to distribute, copy, adapt, reproduce,
                  transmit, create derivative works and otherwise use content
                  and information you post on the program for any purpose and in
                  any media now known or hereinafter developed. You expressly
                  agree that the Savers App Inc. is free to use any ideas,
                  concepts, know-how, or techniques contained in any posting or
                  communication you send to us without compensation and for any
                  purpose whatsoever, including but not limited to developing,
                  manufacturing and marketing products and services using such
                  information. You represent and warrant that you own the
                  content submitted, displayed, published or posted by you in
                  the program and otherwise have the right to grant the licence
                  set forth herein, and the displaying, publishing or posting of
                  any content you submit, and our use thereof does not and will
                  not violate the privacy rights, publicity rights, copyrights,
                  trademark rights, patents, contract rights or any other
                  intellectual property rights or other rights of any person or
                  entity. You further agree that we may, but are not required
                  to, moderate or review any content posted by you to the Site,
                  and may edit or remove such content if we believe it violates
                  these terms and conditions, or is otherwise inappropriate or
                  objectionable. You also acknowledge that any information that
                  you post on our program may be viewable by other users. Savers
                  App Inc. is not responsible for the privacy of any information
                  that you choose to post on our program, any information that
                  you disclose becomes public information
                </li>
              </ol>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Forfeiture or Reversal of Rewards.</h3>
            <p className='mt-2'>
              For rewards earned with an Enrolled Card, if you close the account
              that would be eligible to receive Rewards, then any rewards that
              have not been posted will be forfeited. In our sole discretion, we
              may deduct Rewards from your Eligible Account in order to make
              adjustments for returns and cancellations with respect to
              Qualifying Transactions. In the event that you are awarded Rewards
              from a Qualifying Transaction and subsequently reverse the
              transaction with a Merchant that generated such Rewards, or
              otherwise are required to return the Rewards pursuant to the terms
              of the applicable Offer, whether or not you are still
              participating in the Program, you will remain solely responsible
              for the repayment of the Rewards credited from such purchase. In
              addition to any other rights we may have to recover such funds, we
              may apply future Rewards made for your benefit against such
              obligation. In the event you fail to repay such amount, we reserve
              the right to take any and all legal action necessary to collect
              the Rewards from you, including but not limited to pursuing such
              claim in a court of law. We reserve the right to rescind Rewards
              and to bar further Rewards to, or terminate the participation of,
              any participant that we believe, in our sole discretion, is
              abusing or has abused the Program, including, without limitation,
              by engaging in a pattern of returning products after any
              corresponding Rewards have been credited, or creating or
              attempting to create or participating in any market in Rewards, or
              promoting or encouraging similar behavior by other individuals
              (“Improper Activity”). You are not entitled to compensation from
              us, Program Vendor, nor the Merchant from whom you made the
              applicable purchase, or any other entity, in the event your
              Rewards are forfeited or reversed.
            </p>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Indemnification</h3>
            <p className='mt-2'>
              You agree to indemnify, defend, and hold Savers App Inc, its
              affiliates, and their respective directors, officers, employees,
              agents, representatives, shareholders, successors, and permitted
              assigns, and their licensors and the Program Vendor, Visa and
              Mastercard harmless from losses that arise from third-party claims
              in relation to your breach of the Program Terms, or any fraud or
              other breach of laws by you.
            </p>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Limitation of Liability</h3>
            <div className='mt-2'>
              <p>
                IN NO EVENT WILL Savers App Inc. AND ITS LICENSORS, THE PROGRAM
                VENDOR, VISA OR MASTERCARD, BE LIABLE TO YOU FOR ANY INDIRECT,
                PUNITIVE, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL
                DAMAGES, DAMAGES FOR LOSS OF USE, DATA, INFORMATION, OR REVENUE,
                PROFITS OR BUSINESS INTERRUPTION, WHATSOEVER IN RELATION TO OR
                ARISING UNDER THE PROGRAM TERMS, WHETHER SUCH DAMAGES ARE BASED
                IN TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
                EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p className='mt-4'>
                WITHOUT LIMITING THE FOREGOING, IF SAVERS APP INC OR ITS
                LICENSORS, OR PROGRAM VENDOR, OR VISA OR MASTERCARD SHOULD BE
                FOUND LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT ARISES OUT OF OR
                IS IN ANY CONNECTED WITH THE PROGRAM, SUCH PARTIES’ CUMULATIVE
                LIABILITY WILL IN NO EVENT EXCEED USD$100.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Additional Disclaimers</h3>
            <div>
              <p>
                You waive and release Savers App Inc., its affiliates, licensors
                and their subsidiaries, affiliates, partners, officers,
                directors, employees and agents from any liabilities arising
                from or related to any act or omission of a Merchant in
                connection with your use or redemption of the program or the
                goods, services or experiences that a Merchant provides in
                connection with the Program.
              </p>
              <p className='mt-4'>
                THE SERVICES ARE PROVIDED FOR YOUR CONVENIENCE, “AS IS” AND “AS
                AVAILABLE”, AND Savers App Inc. AND ITS AFFILIATES, LICENSORS
                AND SUPPLIERS, INCLUDING THE PROGRAM VENDOR, VISA AND
                MASTERCARD, EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF
                ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES
                OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, ACCURACY AND NON-INFRINGEMENT.
              </p>
              <p className='mt-4'>
                WE DO NOT WARRANT ANY THIRD-PARTY, ITS PRODUCTS, OR SERVICES,
                INCLUDING THIRD-PARTY LINKS. WE DO NOT VERIFY THE ACCURACY OR
                RELIABILITY OF ANY INFORMATION ON ANY THIRD-PARTY PROPERTY,
                INCLUDING MATERIALS AND PERSON’S COMMENTS ON ANY SITE, AND DO
                NOT REVIEW, APPROVE, MONITOR, ENDORSE, WARRANT, OR MAKE ANY
                REPRESENTATIONS OF ANY KIND IN RESPECT OF SUCH MATERIALS.
              </p>
              <p className='mt-4'>
                YOUR ONLY REMEDY WITH RESPECT TO ANY DISSATISFACTION WITH THE
                PROGRAM, OTHER SERVICES OR ANY MATERIALS, WILL BE TO CANCEL YOUR
                PROGRAM ACCOUNT AND TO STOP USING THE PROGRAM.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Term</h3>
            <p className='mt-2'>
              This Agreement and the license granted hereunder shall remain in
              effect until terminated as set forth herein. Term does not end
              until all Cards are unenrolled from the Program and your Program
              Account is deactivated.
            </p>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Termination and Suspension of Program</h3>
            <div>
              <p>
                Terms for Card-linked Offers may change, or the Offer may expire
                or terminate at any time without additional obligation to you.
                Neither Savers App Inc. nor its license source or third-party
                service provider involved in the Program shall be liable to you
                for any suspension, modification or termination of a Card-linked
                Offer or the Program.{' '}
              </p>
              <p className='mt-4'>
                Without limiting anything else in the Program Terms, Savers App
                Inc. reserves the right, in its sole discretion, to suspend or
                terminate your participation in one or more Card-linked Offers
                if we suspect that any information you provide is inaccurate or
                incomplete, or you fail to meet the eligibility requirements,
                fail to comply with the terms of any Offers or otherwise violate
                the Program Terms.{' '}
              </p>
              <p className='mt-4'>
                Upon cancellation, termination or deactivation of your Program
                Account, (i) all rights, licenses, consents and authorizations
                granted to you pursuant to the Program Terms shall immediately
                terminate, and (ii) we may pay out any amounts to which you are
                entitled in respect of monies present in your Program Account.{' '}
              </p>
              <p className='mt-4'>
                Notwithstanding anything to the contrary in the Program Terms,
                with respect to information and materials in our possession or
                control at the time of cancellation, termination or deactivation
                of your Program Account: (i) we may retain your data in our
                backup, archive and disaster recovery systems until such data is
                deleted in the ordinary course; and (ii) all information and
                materials described in the foregoing clause shall remain subject
                to all confidentiality, security and other applicable
                requirements of the Program Terms.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Miscellaneous</h3>
            <div className='flex mt-2 ml-4'>
              <ol className='list-lower-alpha'>
                <li className='mt-6'>
                  <strong>Governing Law.</strong> This Agreement and all related
                  documents, and all matters arising out of or relating to this
                  Agreement are governed by, and construed in accordance with,
                  the laws of the State of Delaware, and the federal laws of the
                  United States of America, applicable therein, without giving
                  effect to any choice or conflict of law provision or rule
                  (whether of the state of Delaware or any other jurisdiction).
                </li>
                <li className='mt-4'>
                  <strong>Dispute Resolution through Arbitration.</strong>{' '}
                  Unless prohibited by applicable law, any controversy,
                  allegation, or claim arising out of or related to the Program
                  or these Terms, or Privacy Policy, including, without
                  limitation, products and services offered in the Program and
                  merchandise orders made through the Program (“Dispute”), will
                  be resolved solely by binding arbitration in accordance with
                  the then-current Rules of Arbitration of the International
                  Chamber of Commerce (“ICC”), subject to these Terms. Savers
                  App Inc., in its sole discretion, will elect the site of
                  arbitration, which shall be either (i) located within one
                  hundred miles of your residence or (ii) hosted online, with
                  the arbitration conducted over the Internet or (iii) in the
                  State of Delaware. The arbitrator will apply law selected in
                  Section 14 of these Terms and the other provisions of these
                  Terms, will determine any Dispute according to the governing
                  law and facts based upon the record and no other basis, and
                  will issue a reasoned award. You accept the arbitrator’s (or
                  arbitration panel’s) decision as binding. You may obtain
                  information about the International Chamber of Commerce and
                  its current Rules of Arbitration at{' '}
                  <a
                    href='http://www.iccwbo.org/ICCDRSRules/'
                    target='_blank'
                    rel='noreferrer'
                    className='text-blue-700'
                  >
                    http://www.iccwbo.org/ICCDRSRules/
                  </a>
                </li>
                <li className='mt-4'>
                  <strong>Interim Relief.</strong>
                  Prior to the appointment of the Arbitrator, the Parties may
                  apply to the courts for interim relief. A request for interim
                  relief by a Party to court will not be considered to be
                  incompatible with Section 14 or as a waiver of that provision.
                </li>
                <li className='mt-4'>
                  <strong>Declaratory Relief.</strong> The Arbitrator may award
                  declaratory or injunctive relief only in favour of the
                  individual party seeking relief and only to the extent
                  necessary to provide relief warranted by that party’s
                  individual claim. Subject to the exception set out in respect
                  of the parties who may be a named party to a claim, TO THE
                  FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AND Savers App
                  Inc. Inc. D.B.A. Savers App AND ITS LICENSORS AGREE THAT EACH
                  MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
                  INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                  ANY PURPORTED GROUP LITIGATION OR PRIVATE ATTORNEY GENERAL
                  PROCEEDING. Further, unless all affected parties agree
                  otherwise, the Arbitrator may not consolidate more than one
                  person’s claims, and may not otherwise preside over any form
                  of a representative or group proceeding. If a court decides
                  that applicable law precludes enforcement of any of this
                  subsection’s limitations as to a particular claim for relief,
                  then that claim (and only that claim) must be severed from the
                  arbitration and may be brought in court.
                </li>
                <li className='mt-4'>
                  <strong>Force Majeure.</strong> We will not be responsible or
                  liable to you, or deemed in default or breach hereunder by
                  reason of any failure or delay in the performance of our
                  obligations hereunder where such failure or delay is due to
                  strikes, labour disputes, civil disturbances, riot, rebellion,
                  invasion, hostilities, war, terrorist attack, embargo, natural
                  disaster, epidemics, pandemics, including, but not limited to,
                  the 2019 novel coronavirus disease (COVID-19) pandemic, acts
                  of God, flood, tsunami, fire, sabotage, fluctuations or
                  non-availability of electrical power, heat, light, air
                  conditioning or your equipment, loss and destruction of
                  property or any other circumstances or causes beyond our
                  reasonable control.
                </li>
                <li className='mt-4'>
                  <strong>Notices.</strong>
                  All notices, requests, consents, claims, demands, waivers and
                  other communications hereunder shall be in writing and shall
                  be deemed to have been given: (i) when delivered by hand; (ii)
                  when received by the addressee if sent by a nationally
                  recognized overnight courier (receipt requested); (iii) on the
                  date sent by facsimile or e-mail (with confirmation of
                  transmission) if sent during normal business hours of the
                  recipient, and on the next business day if sent after normal
                  business hours of the recipient; or (iv) on the second day
                  after the date mailed , by certified or registered mail by
                  USPS, return receipt requested, postage prepaid.
                  Communications to us are to be provided to &lt;&lt;Mailing
                  Address TBD&gt;&gt;, Attention: Legal. We will communicate
                  notices to you via the information you provide us with for the
                  purposes of providing notice.
                </li>
                <li className='mt-4'>
                  <strong>Entire Agreement.</strong>
                  This Agreement, together with all other documents that are
                  incorporated by reference herein, constitutes the sole and
                  entire agreement between the Parties with respect to the
                  subject matter contained herein, and supersedes all prior and
                  contemporaneous understandings, agreements, representations
                  and warranties, both written and oral, with respect to such
                  subject matter.
                </li>
                <li className='mt-4'>
                  <strong>
                    Successors and Assigns; Third-Party Beneficiaries.
                  </strong>
                  Except for the Program Vendor, which shall be a third-party
                  beneficiary under the Program Terms, the Program Terms are for
                  the sole benefit of the Parties hereto and their respective
                  successors and permitted assigns and nothing herein, express
                  or implied, is intended to or shall confer on any other person
                  any legal or equitable right, benefit or remedy of any nature
                  whatsoever under or by reason of the Program Terms.
                </li>
                <li className='mt-4'>
                  <strong>Amendments and Modifications.</strong>
                  The Program Terms may be amended, modified or supplemented
                  from time to time by us in writing and notice will be provided
                  to you upon any such changes occurring. You may not amend,
                  modify or supplement the Program Terms in any way without a
                  signed written agreement of both you and Savers App Inc.. Any
                  attempt by you to do so without a signed written agreement
                  will be void.
                </li>
                <li className='mt-4'>
                  <strong>Waiver.</strong>
                  No waiver by any Party of any of the provisions hereof shall
                  be effective unless explicitly set forth in writing and signed
                  by the Party so waiving. Except as otherwise set forth in this
                  Agreement, no failure to exercise, or delay in exercising, any
                  right, remedy, power or privilege arising from this Agreement
                  shall operate or be construed as a waiver thereof; nor shall
                  any single or partial exercise of any right, remedy, power or
                  privilege hereunder preclude any other or further exercise
                  thereof or the exercise of any other right, remedy, power or
                  privilege.
                </li>
                <li className='mt-4'>
                  <strong>Severability.</strong>
                  If any term or provision of this Agreement is invalid,
                  illegal, or unenforceable in any jurisdiction, such
                  invalidity, illegality or unenforceability shall not affect
                  any other term or provision of this Agreement or invalidate or
                  render unenforceable such term or provision in any other
                  jurisdiction.
                </li>
                <li className='mt-4'>
                  <strong>Language.</strong>
                  The Parties confirm that it is their express wish that this
                  Agreement, as well as any other documents related to this
                  Agreement, including notices, schedules and authorizations,
                  have been and shall be drawn up in the English language only.
                </li>
                <li className='mt-4'>
                  <strong>Headings.</strong>
                  The headings in this Agreement are for reference only and do
                  not affect the interpretation of this Agreement.
                </li>
              </ol>
            </div>
          </li>
        </ol>
      </section>
    </div>
  );
}

export default TermsAndConditionsPage;
