import React, { memo, useCallback } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import { copyEmail, email } from '../../utils/generalFunctions';

function SupportEmail() {
  const { isMobile } = useIsMobile();

  const handleCopy = useCallback(
    (e: React.MouseEvent<HTMLParagraphElement>) => {
      e.preventDefault();
      copyEmail();
    },
    []
  );

  return isMobile ? (
    <p
      className='text-blue-700 cursor-pointer inline'
      onClick={handleCopy}
      role='button'
      aria-label='Copy support email'
      title='Tap to copy email'
    >
      {email}
    </p>
  ) : (
    <a
      href={`mailto:${email}`}
      className='text-blue-700'
      aria-label='Send email to support'
      title='Click to email support'
    >
      {email}
    </a>
  );
}

export default memo(SupportEmail);
