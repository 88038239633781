import toast from 'react-hot-toast';

export function showMobileToast(message: string) {
  const toast = document.createElement('div');
  toast.textContent = message;
  toast.style.position = 'fixed';
  toast.style.top = '20px';
  toast.style.left = '50%';
  toast.style.transform = 'translateX(-50%)';
  toast.style.backgroundColor = 'black';
  toast.style.color = 'white';
  toast.style.padding = '10px 20px';
  toast.style.borderRadius = '5px';
  toast.style.zIndex = '1000';
  document.body.appendChild(toast);

  setTimeout(() => {
    document.body.removeChild(toast);
  }, 4000);
}

export const email = 'support@saversapp.com';
export const copyEmail = () => {
  try {
    navigator.clipboard.writeText(email);
    toast.success('Email copied to clipboard');
  } catch (error) {
    console.log('error: ', error);
    toast.success('Failed to copy email');
  }
};
